<!-- 客户案例 -->
<template>
    <div class="customerCase">
        <div class="banner">
            <h2>已为<span>58家</span>企业提供服务</h2>
            <p>
                致力于为城镇、园区、景区、公建项目、综合体、高校、等各类不动产持有方、物业管理企业提供系统化整体性解决方案
            </p>
        </div>
        <!-- 客户案例 -->
        <div class="content">
            <div class="case">
                <h2>客户案例</h2>
                <div class="container">
                    <div class="item" v-for="(item, index) in caseList" :key="index" @click="caseDetails(index)">
                        <img v-lazy="item.img" alt="" />
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.details }}</p>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer" v-if="false">
            <el-pagination
                class="pagination"
                background
                layout="prev, pager, next"
                :total="1000"
                @current-change="currentChange"
                :current-page.sync="currentPage"
            >
            </el-pagination>
            <div class="more" @click="moreClick()">查看更多</div>
        </footer>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            caseList: [
                {
                    img: require("../../assets/customerCase/1.jpg"),
                    title: "上海财经大学千村调研2.0系统",
                    details: "“千村调查”项目是上海财经大学“211工程”三期创新人才培养项目，已成功实施十三期…",
                },
                {
                    img: require("../../assets/customerCase/2.jpg"),
                    title: "保利物业百优行动品检项目",
                    details: "“百优行动”是保利物业提升服务品质的专项行动，以物业管理基础服务触点动作为契机…",
                },
                {
                    img: require("../../assets/customerCase/3.jpg"),
                    title: "西塘全域化公共服务项目",
                    details: "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导…",
                },
                // {
                //     img: require("../../assets/customerCase/1.jpg"),
                //     title: "美兰湖妇产科医院物业服务中心",
                //     details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设…",
                // },
                // {
                //     img: require("../../assets/customerCase/2.jpg"),
                //     title: "美兰湖妇产科医院物业服务中心",
                //     details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设…",
                // },
                // {
                //     img: require("../../assets/customerCase/3.jpg"),
                //     title: "美兰湖妇产科医院物业服务中心",
                //     details: "助力高等教育院校在“互联网+教育管理应用”、智慧校园、平安校园等相关系统建设…",
                // },
            ], //客户案例
            currentPage: 1, // 当前页码
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 分页器
        currentChange(val) {
            // console.log(val, "val");
        },
        // 加载更多
        moreClick() {
            // console.log("加载更多");
        },
        // 跳转到案例详情
        caseDetails(e) {
            this.$router.push({ name: "caseDetails", params: { index: e } });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "customerCase_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "customerCase_mobile.scss";
}
</style>
